import React, { Component } from 'react';
import * as cs from "./onestream.module.css"

import { navigate } from 'gatsby';

import Img1 from "./static/Vector3.png"
import Img2 from "../../../images/px-OneCard.webp"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "../../../images/onestream.webp"



const OneStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            <div className={cs.FlexItem} >
            <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >Near real-time On-line decision-making information</p>
                <ul>
                    <li>A New concept of keeping a close eye on the fast-changing payments trends and country wide behavior of consumers</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn}  onClick={()=> navigate("/solution",{state:{select_option:"OneStream"}})}  >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default OneStream;