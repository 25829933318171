import React, { Component } from 'react';
import * as cs from "./expertise.module.css"
import Img1 from "./static/Img1.webp"
import Img2 from "./static/Img2.webp"
import Img3 from "./static/Img3.webp"
import Img4 from "./static/Group4.webp"


const Expertise = () => {
    return (
        <div className={cs.main}>
            <h1>Our core expertise are:</h1>
            <div className={cs.wrap} >
                <div className={cs.FlexContainer} >

                    <div className={cs.FlexItem} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="500"  >

                        <img src={Img1} className={cs.img_group} />


                        <p className={cs.text2} >Management consulting & CX/UX design services (Global Benchmark)</p>

                    </div>
                    <div className={cs.FlexItem} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000"  >

                        <img src={Img2} className={cs.img_group} />


                        <p className={cs.text2} >Software solutions for the digital world (Timestream IPs)</p>

                    </div>
                    <div className={cs.FlexItem} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1500"  >

                        <img src={Img3} className={cs.img_group} />


                        <p className={cs.text2} >System integration & product development Services (Customer IP) </p>

                    </div>
                    {/* <div className={cs.FlexItem} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1500"  >

                        <img src={Img1} className={cs.img_group} />


                        <p className={cs.text2} >Consult with Us </p>

                    </div> */}

                </div>
            </div>
        </div>
    );
};

export default Expertise;