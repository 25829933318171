import React, { Component } from 'react';
import * as cs from "./team.module.css"
import Img1 from "../../images/Shahid.webp"
import Img2 from "../../images/Saleem.webp"
import Img3 from "../../images/Jamshed.webp"
import Img4 from "../../images/Shahid_Suri.webp"
import Img5 from "../../images/Jaffar.webp"

import Img_Icon from "../../images/Icon.webp"

import { Link, navigate } from 'gatsby';


const Team = () => {
    return (
        <div className={cs.wrap} id="team" >
        <div className={cs.FlexContainer} >

            <Link className={cs.FlexItem} data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000" to={"/about-us"} state={{ select_option: "Shahid_Osmani" }} >
                <div className={cs.img_div} >
                    <img src={Img1} className={cs.img_group} />
                    <img src={Img_Icon} className={cs.img_Icon} />
                </div>
                <h3 className={cs.title} >Shahid Osmani</h3>
                <p className={cs.text} >Managing Director</p>
            
            </Link>
            <Link className={cs.FlexItem} data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000" to={"/about-us"} state={{ select_option:"Saleem_uz_Zaman"}}    >
                <div className={cs.img_div} >
                    <img src={Img2} className={cs.img_group} />
                    <img src={Img_Icon} className={cs.img_Icon} />
                </div>
                <h3 className={cs.title} >Saleem uz Zaman</h3>
                <p className={cs.text} >Director Business Development</p>
            </Link>
            <Link className={cs.FlexItem} data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000" to={"/about-us"} state={{ select_option:"Jamshed_Rabadi"}} >
                <div className={cs.img_div} >
                    <img src={Img3} className={cs.img_group} />
                    <img src={Img_Icon} className={cs.img_Icon} />

                </div>
                <h3 className={cs.title} >Jamshed Rabadi</h3>
                <p className={cs.text} >Director Finance and Taxation</p>
            
            </Link>
            <Link className={cs.FlexItem} data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000" to={"/about-us"} state={{ select_option:"Shahid_Suri"}} >
                <div className={cs.img_div} >
                    <img src={Img4} className={cs.img_group} />
                    <img src={Img_Icon} className={cs.img_Icon} />

                </div>
                <h3 className={cs.title} >Shahid Suri</h3>
                <p className={cs.text} >Director Technology & Innovation </p>
            
            </Link>
            <Link className={cs.FlexItem} data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000" to={"/about-us"} state={{ select_option:"Jaffer_Hussainee"}}  >
                <div className={cs.img_div} >
                    <img src={Img5} className={cs.img_group} />
                    <img src={Img_Icon} className={cs.img_Icon} />

                </div>
                <h3 className={cs.title} >Jaffer Hussainee</h3>
                <p className={cs.text} >Director Operational Excellence </p>
            
            </Link>
        </div>
   
    </div>
    );
};

export default Team;