import React, { Component } from 'react';
import * as cs from "./Partners.module.css"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// import "./Carousal.css"

import Img1 from "./static/avanza.webp"
import Img2 from "./static/px-software.webp"
import Img3 from "./static/be_agile.webp"
import Img4 from "./static/agility_institute.webp"
import Img5 from "./static/px-sms.webp"
import Img6 from "./static/px-vuca.webp"
import Img7 from "./static/px-tps.webp"
import Img8 from "./static/px-touchpoint.webp"
import Img9 from "./static/px-dubaiGroup.webp"
import Img10 from "./static/px-itech.webp"
import Img11 from "./static/px-1link.webp"
import Img12 from "./static/px-hisab.webp"
import Img13 from "./static/px-sps.webp"
import Img14 from "./static/px-Ebtikar.webp"
import Img15 from "./static/px-gbm.webp"





import { navigate } from 'gatsby';


const Partners = () => {


      var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
      
        // pauseOnHover: true,
        //   speed: 1000,
        responsive: [
            {
                breakpoint: 1445,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                //   dots: true
                }
              },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            //   dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              // initialSlide: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            }
          }
        ],

      };

    return (
        <div className={cs.wrap} id="partners" >
                      <h1 className={cs.partners_h1} >Partners  &  VAR – Value Added Reseller </h1>
            <Slider {...settings}>
                <a className={cs.FlexItem}  href="https://www.avanzasolutions.com/" target="_blank" >
                    <img src={Img1} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.softwareag.com/" target="_blank"  >
                    <img src={Img2} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.beagile.co.za/" target="_blank"   >
                    <img src={Img3} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://businessagility.institute/" target="_blank"   >    
                    <img src={Img4} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem} href="https://smsit.com.sa/" target="_blank"  >
                    <img src={Img5} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.vuca-world.org/" style={{padding:10}} target="_blank"   >    
                    <img src={Img6} className={cs.img_group} />
                </a>

                <a className={cs.FlexItem}  href="https://www.tpsworldwide.com/" target="_blank"   >    
                    <img src={Img7} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://touchpoint.pk/" target="_blank"   >    
                    <img src={Img8} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.dqg.org/" target="_blank"   >    
                    <img src={Img9} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.itechsol.com.au/" target="_blank"   >    
                    <img src={Img10} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://1link.net.pk/" target="_blank"   >    
                    <img src={Img11} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://hysabkytab.com/" target="_blank"   >    
                    <img src={Img12} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://www.spsnet.com/" target="_blank"   >    
                    <img src={Img13} className={cs.img_group} />
                </a>
                <a className={cs.FlexItem}  href="https://ebtikar.io/" target="_blank"   >    
                    <img src={Img14} className={cs.img_group} />
                </a>
                {/* <a className={cs.FlexItem}  href="https://gbmme.com/" target="_blank"   >    
                    <img src={Img15} className={cs.img_group} />
                </a> */}
            </Slider>

        </div>
    );
};

export default Partners;