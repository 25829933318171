import React, { Component } from 'react';
import * as cs from "./info.module.css"
import Img1 from "./static/Group.webp"
import Img2 from "../../images/timestream.webp"


const Info = () => {
    return (
        <div className={cs.main} id="info">
            {/* <h1 className={cs.main_h} >About Us</h1> */}
            <div className={cs.wrap} >

                <div className={cs.FlexContainer} >
                    <div className={cs.FlexItem1}  data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000"  >
                    <img src={Img1} className={cs.img_group} />
                    </div>

                    <div className={cs.FlexItem2}  data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000"  >
                    <img src={Img2} className={cs.img_title} />
                    <ul>
                        <li>A team of professionals with 125+ years of solid combined experience in business management consultancy, IT solutions designing, delivery and fulfillment.</li>
                        <li>With a diversified industry experience in Banking, Telecom, Retail and Government</li>
                        <li>Have worked for major international and local brands like VISA, NCR, IBM, Dubai Customs, Ports Customs & Freezone Corporation, The Executive Council of Dubai, Dubai World and Al Futtaim group.</li>
                    </ul>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default Info;