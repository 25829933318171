import React, { Component } from 'react';
import * as cs from "./srcstream.module.css"
// import Img1 from "./static/Group.webp"
import { navigate } from 'gatsby';
import Img1 from "./static/Vector3.png"
import Img2 from "./static/Picture3.png"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "./static/srcstream.png"



const SrcStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            {/* <img src={Img2} className={cs.img_style2} /> */}

            <div className={cs.FlexItem} >
                {/* <p className={cs.title} >secureSTREAM </p> */}
                <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >Integrated Logistics through digitalization</p>
                <ul>
                    <li>An integrated supply chain gives businesses end to end visibility, greater flexibility, and the ability to vary transportation modes across ocean, air, and land.​</li>
                    <li>It improves agility, resilience, and ability to respond to disruptions quickly and successfully – all of which is important to a business stepping into a new market.</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn} onClick={()=>navigate("/services",{state:{select_option:"managment_consulting"}})}  >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default SrcStream;