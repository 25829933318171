
// import * as cs from "./Carousal.module.css"

// import "./carousal_func"

import React, { Component } from 'react';
import Slider from "react-slick";
import * as cs from "./carousal.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Carousal.css"

import ReconStream from './reconStream/reconStream';
import AgileStream from './agileStream/agileStream';
import DesignStream from './designStream/designStream';
import ProcessStream from './processStream/processStream';
import SecureStream from './secureStream/secureStream';
import OneStream from './oneStream/oneStream';
import SrcStream from './srcStream/srcStream';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  autoplay: true,
  pauseOnHover: true,
  // adaptiveHeight: true
  speed: 1000,
  autoplaySpeed: 5000,
    
  // beforeChange: function(currentSlide, nextSlide) {
  //   console.log("before change", currentSlide, nextSlide);
  // },
  // afterChange: function(currentSlide) {
  //   console.log("after change", currentSlide);
  // }
};
const Carousal = () => {

  return (

    <div className={cs.wrap} data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"   >

      <Slider {...settings}>

        <div className={cs.slide_div}>
          <ReconStream />
        </div>
        <div className={cs.slide_div}>
          <AgileStream />
        </div>
        <div className={cs.slide_div}>
          <DesignStream />
        </div>
        <div className={cs.slide_div}>
          <ProcessStream />
        </div>
        <div className={cs.slide_div}>
          <SecureStream />
        </div>
        <div className={cs.slide_div}>
          <OneStream />
        </div>
        <div className={cs.slide_div}>
          <SrcStream />
        </div>

      </Slider>
    </div>
  );
};

export default Carousal;