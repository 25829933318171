import React, { Component } from 'react';
import * as cs from "./How.module.css"

const How = () => {
    return (
        <div className={cs.main}>
        <h1>How We Do It?</h1>
        <div className={cs.wrap} >
            <div className={cs.FlexContainer} >

                <div className={`${cs.FlexItem} ${cs.c1}`}   data-aos="fade-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="500"   >
                    <p  className={cs.title} >Decision Value Stream</p>
                    <ul>
                        <li>View “past” performance and measure baseline.</li>
                        <li>Visualize “future” - targeted KPIs, OKRs and how performance should be measured and reported</li>
                        <li>Enable “present” tools and technologies to analyze data for agile decision making</li>
                    </ul>
                </div>
                <div className={`${cs.FlexItem} ${cs.c2}`}   data-aos="fade-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1000"   >
                <p  className={cs.title} >Operational Value Stream</p>
                    <ul>
                        <li>View how work is done in the “past”.</li>
                        <li>Predict how work will be done, and possible disruptions in the “future”</li>
                        <li>Enable “present” tools and technologies to realize the future vision</li>
                    </ul>
                </div>
                <div className={`${cs.FlexItem} ${cs.c3}`}   data-aos="fade-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"   >
                <p  className={cs.title} >Product / Service Delivery Value Stream</p>
                    <ul>
                        <li>View how services were delivered in the “past”.</li>
                        <li>Predict customer expectations in the “future”</li>
                        <li>Enable “present” tools and technologies to meet customer expectations now and in the future, in an agile way</li>
                    </ul>
                </div>
                  
            </div>
        </div>
         </div>
    );
};

export default How;