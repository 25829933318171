import React, { Component, useEffect, useState } from 'react';
import * as cs from "./value.module.css"
import ImageMarker from "react-image-marker";
import Swal from 'sweetalert2'
// import Map from "../../images/map.webp"
// import Map from "../../images/world.webp"
import Map from "../../images/world4.png"

import Arrow from "./static/location6.png"
import Img1 from "./static/avanza.webp"
import Img2 from "./static/px-software.webp"
import Img3 from "./static/be_agile.webp"
import Img4 from "./static/agility_institute.webp"
import Img5 from "./static/px-sms.webp"
import Img6 from "./static/px-vuca.webp"
import Img7 from "./static/px-tps.webp"
import Img8 from "./static/px-touchpoint.webp"
import Img9 from "./static/px-dubaiGroup.webp"
import Img10 from "./static/px-itech.webp"
import Img11 from "./static/px-1link.webp"
import Img12 from "./static/px-hisab.webp"
import Img13 from "./static/px-sps.webp"
import Img14 from "./static/px-Ebtikar.webp"
import Img15 from "./static/px-gbm.webp"


import { navigate } from 'gatsby';

let Partners_Data = [
    {  top:41.2 , left: 66.9, name: ["1Link", "HysabKytab"], city: ["Karachi","Karachi"], country:"Pakistan", url: ["https://1link.net.pk/", "https://hysabkytab.com/"], img_src: [Img11, Img12] },
    { top: 44.5, left: 63.3, name: ["Dubai Quality Group (DQG)"  ], city: ["Dubai"], country:"UAE", url: ["https://www.dqg.org/"], img_src: [Img9] },
    { top: 32.26, left: 14.4, name: ["Business Agility Institute (BAI)","Software Productivity Strategists, Inc. (SPS)"], city: ["Irvine, California","Rockville, MD. 20850, USA"], country:"USA", url: ["https://businessagility.institute/","https://www.spsnet.com/"], img_src: [Img4,Img13] },
    { top: 71.6, left: 50.5, name: ["Be Agile"], city: ["South Africa"], country:"South Africa", url: ["https://www.beagile.co.za/"], img_src: [Img3] },
    { top: 40.5, left: 59.3, name: ["Ebtikar"], city: ["Kuwait City"], country:"Kuwait", url: ["https://ebtikar.io/"], img_src: [Img14] },
    { top: 71.2, left: 91.6, name: ["Innovative Technological Solutions  - Itechsol - Australia"], city: ["Sydney"], country:"Australia", url: ["https://www.itechsol.com.au/"], img_src: [Img10] },
        { top: 34.9, left: 43.9, name: ["VUCA- World", "Software AG"], city: ["Darmstadt","Darmstadt"], country:"Germany", url: ["https://www.vuca-world.org/", "https://www.softwareag.com/"], img_src: [Img6, Img2] },
    // { top: 42.9, left: 21.3, name: ["Software Productivity Strategists, Inc. (SPS)"], city: "Rockville, MD. 20850, USA", country:"USA", url: ["https://www.spsnet.com/"], img_src: [Img13] },

]



let VAR_Data = [
    { top:41.2 , left: 66.9, name: ["Avanza", "TPS", "TouchPoint (Pvt) Ltd","Software Productivity Strategists, Inc"], city: ["Lahore/ Karachi","Karachi","Karachi","Islamabad"], country:"Pakistan", url: ["https://avanzasolutions.com/", "https://www.tpsworldwide.com/", "https://touchpoint.pk/","https://www.spsnet.com/"], img_src: [Img1, Img7, Img8, Img13] },
    { top: 44.5, left: 63.3, name: ["Avanza", "TPS",], city: ["Dubai","Dubai"], country:"UAE", url: ["https://avanzasolutions.com/", "https://www.tpsworldwide.com/"], img_src: [Img1, Img7] },
    { top: 45.65, left: 59.4, name: ["Smart Management Solutions"], city: ["Riyadh"], country:"Saudi Arabia", url: ["https://smsit.com.sa/"], img_src: [Img5] },
]


const Value = () => {
    let [markers, setMarkers] = useState(VAR_Data);

    //     useEffect(() => {
    //     let url = window.location.href.split("/#")
    //     console.log(url)
    //     if(url[1]=="partners"){
    //         setMarkers(Partners_Data)
    //     }
    //     if(url[1]=="var"){
    //         setMarkers(VAR_Data)
    //     }
    // },[])

    const CustomMarker = (info) => {

        function markerDetails() {

            Swal.fire({
                title: info.country,
                showConfirmButton: false,
                // text: info.city,
                html:
                    info.name.map((c_name, index) =>
                        `<div style="display:inline-flex;align-items:center; justify-content: space-between; width: 100%; cursor:pointer; background:"teal"; >
                          
                            <div style="display:inline-flex;align-items:center; " ><img src=${info.img_src[index]} style="height:30px;" /><p style="font-size:14px; font-style:italic; margin-left:10px " >  (${info.city[index]})</p></div> 
                            <a href=${info.url[index]} target="_blank" style="color:white;margin-top:10px; padding:10px; background:#0198CD; border-radius:7px; font-size:12px; letter-spacing:1px" >Explore</a>
                         </div><br />`
                    )
            });
        }

        return (
            <div
                className={cs.marker_div}
                onClick={() => markerDetails()} >
                <p className={cs.marker_country}>{info.country}</p>
                <img src={Arrow} className={cs.location} />
            </div>
        );
    };

    return (
        <div className={cs.main} id="var">

            <div className={cs.wrap} >
                <div className={cs.FlexContainer} >

                    <div className={cs.FlexItem} onClick={() => setMarkers(VAR_Data)} >
                        <p className={cs.title} >Value Added Reseller</p>
                    </div>

                    <div className={cs.FlexItem} onClick={() => setMarkers(Partners_Data)} >
                        <p className={cs.title} >Partners</p>
                    </div>


                </div>
            </div>
            <ImageMarker
                src={Map}
                markers={markers}
                // onAddMarker={(marker) => {
                //     console.log("--",marker);
                //     setMarkers((prev) => [...prev, marker]);
                // }}

                markerComponent={CustomMarker}
            />


        </div>
    );
};

export default Value;