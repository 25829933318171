import * as React from "react"
import Home from "../components"
import SEO from "../components/gatsby/seo"
import "../style.css"

const IndexPage = ({ location }) => {
  return (
    <React.Fragment>

      <SEO title="Timestream Group"
        ogtitle="Timestream Group"
        description="Timestream Management and Solutions FZCO is a start-up software and services company registered in Dubai Free zone with the aim of providing quality industry agnostic digital solutions to enable organizations streamline operations and improve decision making."
        url="/"
        image="https://timestreamgroup.com/timestream_icon.png" />
      <Home location={location} />
    </React.Fragment>
  )
}

export default IndexPage


