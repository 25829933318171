import React, { Component } from 'react';
import * as cs from "./processtream.module.css"
import { navigate } from 'gatsby'
import Img1 from "./static/Vector3.png"
import Img2 from "../../../images/px-ProcessCard.webp"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "../../../images/processStream.webp"


const ProcessStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            <div className={cs.FlexItem} >
            <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >95% of the total process time is idle time comprising of handoff time, waiting in queue to be worked upon etc.</p>
                <ul>
                    <li>Business process management (BPM) is the discipline in which people use various methods to discover, model, analyze, measure, improve, optimize, and automate business processes​</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn}  onClick={()=>navigate("/services",{state:{select_option:"managment_consulting"}})}  >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default ProcessStream;