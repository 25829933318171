import React, { Component } from 'react';
import * as cs from "./contact.module.css"
import Img1 from "./static/phone-call.webp"
import Img2 from "./static/mail.webp"

const Contact = () => {
    return (
        <div className={cs.main} id="contact">
     
        <div className={cs.wrap} >
        <h1 className={cs.wrap_h1} >Contact Us</h1>
            <div className={cs.FlexContainer} >

                <div className={cs.FlexItem}   data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"   >
                    <p  className={cs.title} >Timestream Management & Solutions FZ</p>
                    <ul>
                        <li>Quarter Deck</li>
                        <li>QE2, </li>
                        <li>Port Rashid, </li>
                        <li>Mina Road, </li>
                        <li>Dubai,</li>
                        <li>United Arab Emirates</li>
                       
                    </ul>
                    <div className={cs.contact_bottom}>
                        <img src={Img1} className={cs.img_icon} />
                       <a href="tel:+971555920527" ><p >(+971) 55 592 0527</p></a> 
                    </div>
                    <div className={cs.contact_bottom}>
                        <img src={Img2} className={cs.img_icon} />
                        <a href="mailto:info@timestreamgroup.com"> <p>info@timestreamgroup.com</p></a>
                    </div>
                </div>
                <div className={cs.FlexItem}   data-aos="zoom-in-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease" data-aos-duration="1500"   >
                    <p className={cs.title} >Timestream Group – Technology Wing </p>
                    <ul>
                        <li>Marlow House,</li>
                        <li>Windsor Road, </li>
                        <li>Maidenhead,</li>
                        <li>SL6 2EW,</li>
                        <li>United Kingdom </li>
                    </ul>
                    <div className={cs.contact_bottom}>
                        <img src={Img1} className={cs.img_icon} />
                        <a href="tel:+447960244662" ><p >(+44) 7960244662</p></a>
                    </div>
                    <div className={cs.contact_bottom}>
                        <img src={Img2} className={cs.img_icon} />
                        <a href="mailto:info@timestreamgroup.com"> <p >info@timestreamgroup.com</p></a>
                    </div>
                </div>

                  
            </div>
        </div>
         </div>
    );
};

export default Contact;