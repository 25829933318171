import React, { Component } from 'react';
import * as cs from "./Consultation.module.css"
import { navigate,Link } from 'gatsby';



const Consultation = () => {
    return (
        <div className={cs.main} id="consultation" >
            <h1>Consulting Services</h1>
            <div className={cs.wrap} >
                <div className={cs.FlexContainer} >
                    
                    <Link className={cs.FlexItem} to={"/services"} state={{select_option:"managment_consulting"}} >
                    <p className={cs.title} >Management Consulting</p>
                    </Link>

                    <Link className={cs.FlexItem} to={"/services"} state={{ select_option:"technical_consulting"}} >
                    <p className={cs.title} >Technical Consulting</p>
                    </Link>


                </div>
            </div>
            <div className={cs.bgImg}  >

            </div>
        </div>
    );
};

export default Consultation;