import React, { Component } from 'react';
import About from './about/About';
import Carousal from './Carousal/Carousal';
import Consultation from './consultation/Consultation';
import Contact from './contact/Contact';
import Expertise from './expertise/Expertise';
import Footer from './Footer/Footer';
import HeroComp from './Hero/hero';
import How from './How_we_do/How';
import Info from './Info/Info';
import Partners from './Partners/Partners';
import Solution from './solutions/Solutions';
import Team from './Team/Team';
import Value from './Value/Value';
import What_we_do from './what_we_do/what_we_do';
import Top from "../images/top_arrow.png"


const Home = () => {
    return (
        <div style={{display:"flex",flexDirection:"column",position:"relative" }}>
        <HeroComp />
        <What_we_do />
        <How />
        <Carousal />
        <Solution />
        <Consultation />
        <About />
        <Expertise />
        <Team />
        <Info />
        <Partners />
        <Value />
        <Contact />
        <Footer />
        
       
            <div onClick={() => 
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })} 
            className="top" >
                <img src={Top} style={{width:15,filter:"brightness(10)"}} />
            </div>


        </div>

    );
};

export default Home;




// import React, { Component } from 'react';
// import * as cs from "./home.module.css"

// const Home = () => {
//     return (
//         <h1>Home</h1>
//     );
// };

// export default Home;

