// extracted by mini-css-extract-plugin
export var FlexContainer = "hero-module--FlexContainer--mk01f";
export var FlexItem = "hero-module--FlexItem--L2A7r";
export var hero = "hero-module--hero--xCxgy";
export var hero_btn = "hero-module--hero_btn--o9q68";
export var hero_container = "hero-module--hero_container--B5ejQ";
export var hero_h1 = "hero-module--hero_h1--AMoG3";
export var hero_h1a = "hero-module--hero_h1a--8VYix";
export var hero_p = "hero-module--hero_p--xxnLF";
export var img_div = "hero-module--img_div--EAyX2";
export var img_group = "hero-module--img_group--61e5+";
export var text = "hero-module--text--kgQgj";
export var text2 = "hero-module--text2--J7idV";
export var title = "hero-module--title--vO7hm";
export var wrap = "hero-module--wrap--x9pmu";