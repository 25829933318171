import React, { Component } from 'react';
import * as cs from "./Designstream.module.css"

import { navigate } from 'gatsby';
import Img1 from "./static/Vector3.png"
import Img2 from "../../../images/px-DesignCard.webp"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "../../../images/designStream.webp"



const DesignStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            <div className={cs.FlexItem} >
            <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >A paradigm shift in the “Digital Business”</p>
                <ul>
                    <li>End to end Digital branch consulting service that builds your future banking, targeting Generation Z​</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn}  onClick={()=>navigate("/services",{state:{select_option:"technical_consulting"}})}  >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default DesignStream;