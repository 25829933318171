import { navigate } from 'gatsby';
import React, { Component } from 'react';
import * as cs from "./reconstream.module.css"
// import Img1 from "./static/Group.webp"
import Img1 from "./static/Vector3.png"
import Img2 from "../../../images/px-ReconCard.webp"

import Img_Title from "../../../images/recon.webp"



const ReconStream = () => {
    return (

        <div className={cs.wrap} >

            {/* <div className={cs.FlexContainer} > */}
                <img src={Img1} className={cs.img_style} />
                {/* <img src={Img2} className={cs.img_style2} /> */}


                <div className={cs.FlexItem} >
                <img src={Img_Title} className={cs.img_title} />
                    <p className={cs.sub_title} >A next generation reconciliation system</p>
                    <ul>
                        <li>Our cloud-native, automated and near real-time reconciliation system helps in achieving highest operational efficiencies by eliminating human errors, reducing operational risk, handling high transaction volumes while also addressing frequently changing regulatory landscape.​</li>
                       
                    </ul>

                    <div className={cs.btn_row} >
                        <div className={cs.hero_btn} onClick={()=> navigate("/solution",{state:{select_option:"ReconStream"}})} >Learn More</div>
                    </div>
                </div>
                
                <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default ReconStream;

