import React, { Component } from 'react';
import * as cs from "./about.module.css"
import Img1 from "./assets/recon.png"
import Img2 from "./assets/onestream.png"


const About = () => {
    return (
        <div className={cs.main} id="about" >
                <h1 className={cs.main_h} >About Us</h1>
            <div className={cs.wrap} >
            {/* <h3 className={cs.main_h3}  >About Us  Text</h3> */}
        
                <div className={cs.FlexContainer}  data-aos="fade-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1000"  >
                    <div className={cs.FlexItem}  >
                       <p className={cs.text} >Timestream Group initially started its journey from UK as a software and services consulting firm that was serving some big brands in the UK Banking and Retail industry by one of our directors. </p>
                       <p className={cs.text}  >Our model is simply focused on building an agile foundation for organizations and help them transform their business into the digital world by using our software solutions, system integration services and methodologies.</p>
                    </div>

                    <div className={cs.FlexItem} >
                        <p className={cs.text} >Timestream Group has opened its first office in the UAE with a mission to expand global brand recognition in B2B technology.</p>
                        <p className={cs.text} >Timestream has partnered with Software AG - Germany, Business Agility Institute - USA, Be Agile – South Africa, Avanza Solutions UAE, TPS UAE, Tree House UAE, SPS-Net USA, VUCA - World, Germany and many more on the roadmap, to enable us deliver strong solutions and consulting services to our partners and their customers.</p>
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default About;