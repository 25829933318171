import React, { Component } from 'react';
import * as cs from "./agilestream.module.css"
// import Img1 from "./static/Group.webp"
import { navigate } from 'gatsby';
import Img1 from "./static/Vector3.png"
import Img2 from "../../../images/px-AgileCard.webp"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "../../../images/agileStream.webp"



const AgileStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            {/* <img src={Img2} className={cs.img_style2} /> */}

            <div className={cs.FlexItem} >
                {/* <p className={cs.title} >secureSTREAM </p> */}
                <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >How ready and capable your organization is today for any change?</p>
                <ul>
                    <li>Agility profile leading to a certification​

focusing on customer centricity, growth and Innovation and high performance ​</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn} onClick={()=>navigate("/services",{state:{select_option:"managment_consulting"}})}   >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default AgileStream;