import React, { Component } from 'react';
import * as cs from "./securestream.module.css"
// import Img1 from "./static/Group.webp"
import { navigate } from 'gatsby';
import Img1 from "./static/Vector3.png"
import Img2 from "./static/Group2.jpeg"
// import Img_Title from "../../../images/secureStream.webp"
import Img_Title from "./static/secure.png"



const SecureStream = () => {
    return (

        <div className={cs.wrap}  >

            <img src={Img1} className={cs.img_style} />
            {/* <img src={Img2} className={cs.img_style2} /> */}

            <div className={cs.FlexItem} >
                {/* <p className={cs.title} >secureSTREAM </p> */}
                <img src={Img_Title} className={cs.img_title} />
                <p className={cs.sub_title} >Security is not an option anymore</p>
                <ul>
                    <li>Build a regular external security audit service for your customer's touchpoints, both on customer facing and backend systems & infrastructure​ ​</li>
                </ul>

                <div className={cs.btn_row} >
                    <div className={cs.hero_btn} onClick={() => navigate("/services", { state: { select_option: "technical_consulting" } })}  >Learn More</div>
                </div>
            </div>
            <div className={cs.FlexItem} >
                <img src={Img2} className={cs.img_style3} />
            </div>
        </div>

    );
};

export default SecureStream;