import React, { Component } from 'react';
import * as cs from "./what.module.css"
import Img1 from "./static/Group1.webp"
import Img2 from "./static/Group2.webp"
import Img3 from "./static/Group3.webp"
import Img4 from "./static/Group4.webp"


const What_we_do = () => {
    return (
        <div className={cs.main}>
        <h1>What We Do</h1>
        <div className={cs.wrap} >
            <div className={cs.FlexContainer}  data-aos="fade-up"  data-aos-anchor-placement="top-bottom" data-aos-easing="ease" data-aos-duration="1000"  >

                <div className={cs.FlexItem} >
                    <div className={cs.img_div} >
                        <img src={Img1} className={cs.img_group} />
                    </div>
                   
                    <p className={cs.text2} >We help organization identify business areas that are prime candidates for agile transformation</p>
                
                </div>
                <div className={cs.FlexItem} >
                    <div className={cs.img_div} >
                        <img src={Img2} className={cs.img_group} />
                    </div>
            
                    <p className={cs.text2} > We enable business value stream agile transformation through our portfolio of products and services
                    </p>
                
                </div>
                <div className={cs.FlexItem} >
                    <div className={cs.img_div} >
                        <img src={Img3} className={cs.img_group} />
                    </div>
              
                    <p className={cs.text2} >We assist organizations eliminate pain points in part or all areas of their business value stream by laying an agile foundation </p>
                
                </div>
                <div className={cs.FlexItem} >
                    <div className={cs.img_div} >
                        <img src={Img4} className={cs.img_group} />
                    </div>
                   
                    <p className={cs.text2} > We facilitate value realization of organizations IT investments enabling them tackle disruptions in an agile way </p>
                
                </div>
            </div>
        </div>
         </div>
    );
};

export default What_we_do;