import React, { Component } from 'react';
import * as cs from "./hero.module.css"
import Img1 from "./static/Group1.webp"
import Img2 from "./static/Group2.webp"
import Img3 from "./static/Group3.webp"
import Img4 from "./static/Group4.webp"
// import Header from '../Header/Header';
import Header from '../Header/Main_Header';




const HeroComp = () => {
    return (
     <>
     <div className={cs.hero}  >
        <Header />
     <div className={cs.hero_container}  >
        <div className={cs.hero_h1}  data-aos="fade-right" data-aos-easing="ease" data-aos-duration="1000" >
            Enabling organization agility through <span className={cs.hero_h1a}>digital transformation</span>
        </div><br/>
        <p className={cs.hero_p}  data-aos="fade-right" data-aos-easing="ease" data-aos-duration="1500"  >Primary choice for agile transformation consulting, leveraging IT investments</p>
        {/* <div className={cs.hero_btn}  data-aos="fade-right" data-aos-easing="ease" data-aos-duration="1800"  ><a href="/#info">Learn More</a></div> */}
     </div>
     </div>
        <div className={cs.wrap} >
            <div className={cs.FlexContainer} >

                <div className={cs.FlexItem}  data-aos="fade-up"   data-aos-easing="ease-in" data-aos-duration="500"   >
                    <div className={cs.img_div} >
                        <img src={Img1} className={cs.img_group} />
                    </div>
                    <h3 className={cs.title} >Vision</h3>
                    <p className={cs.text} >To be the primary choice for agile transformation consulting, leveraging IT investments</p>
                
                </div>
                <div className={cs.FlexItem}  data-aos="fade-up"  data-aos-easing="ease-in" data-aos-duration="700"  >
                    <div className={cs.img_div} >
                        <img src={Img2} className={cs.img_group} />
                    </div>
                    <h3 className={cs.title} >Values</h3>
                    <p className={cs.text} ><strong>Integrity:</strong> Acting with honesty and integrity without compromising code of professional and business ethics</p>
                    <p className={cs.text} ><strong>Passion:</strong> Committing our hearts and minds to give our best at all times and to add impactful value</p>
                    <p className={cs.text} ><strong>Collaboration:</strong> Giving due respect to self and others while maintaining the environment of teamwork and positive relationships</p>
                    <p className={cs.text} ><strong>Practicality:</strong> Adopting a practical but innovative approach in our design of solutions that are easy to manage, implement and customize</p>
                    <p className={cs.text} ><strong>Fun:</strong> Enjoying the learning and development experiences as a team</p>
                
                </div>
                <div className={cs.FlexItem}   data-aos="fade-up" data-aos-easing="ease-in" data-aos-duration="1000"  >
                    <div className={cs.img_div} >
                        <img src={Img3} className={cs.img_group} />
                    </div>
                    <h3 className={cs.title} >Philosophy</h3>
                    <p className={cs.text} >Business is like a flowing stream and leaves flowing in the stream are services or products. Obstacles hinder the smooth flow of the leaves, demanding removal of such obstacles, or altering the path of the stream as a priority to ensure a smooth business stream flow</p>
                
                </div>
                {/* <div className={cs.FlexItem}  data-aos="fade-up"  data-aos-anchor-placement="top-center" data-aos-easing="ease-in" data-aos-duration="1300"  >
                    <div className={cs.img_div} >
                        <img src={Img4} className={cs.img_group} />
                    </div>
                    <h3 className={cs.title} >Consult with us</h3>
                    <p className={cs.text} >Get your business diagnosed and pain points removed by our team </p>
                
                </div> */}
            </div>
       
        </div>

        </> 

    );
};

export default HeroComp;
