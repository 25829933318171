// extracted by mini-css-extract-plugin
export var FlexContainer = "value-module--FlexContainer--KFJy7";
export var FlexItem = "value-module--FlexItem--CR5Bd";
export var bgImg = "value-module--bgImg--8fMFV";
export var img_bg = "value-module--img_bg--ErnnI";
export var img_group = "value-module--img_group--DzBb7";
export var location = "value-module--location--53BSi";
export var main = "value-module--main--zmrYx";
export var marker_country = "value-module--marker_country--9VfjD";
export var marker_div = "value-module--marker_div--Xs5eg";
export var title = "value-module--title--r1aer";
export var wrap = "value-module--wrap--yqSVx";