import React, { Component } from 'react';
import * as cs from "./solution.module.css"
import Img1 from "../../images/recon.webp"
// import Img1 from "../../images/reconstream.png"

import Img2 from "../../images/onestream.webp"
// import Img2 from "../../images/onestream.png"


import { Link, navigate } from 'gatsby';


const Solution = () => {
    return (
        <div className={cs.main} id="solutions">
            <h1>Solutions</h1>
            <div className={cs.wrap} >
                <div className={cs.FlexContainer} >

                    <Link className={cs.FlexItem} to={"/solution"} state={{ select_option:"ReconStream"}}  >
                        <img src={Img1} className={cs.img_group} />
                    </Link>

                    <Link className={cs.FlexItem} to={"/solution"} state={{ select_option:"OneStream"}}  >
                        <img src={Img2} className={cs.img_group} />
                    </Link>


                </div>
            </div>
        </div>
    );
};

export default Solution;